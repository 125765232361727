import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import classNames from 'classnames';
import ReactMarkdown from "react-markdown"

/**
 * Components
 */
import ListImageBullets from '@components/list-image-bullets/list-image-bullets';
import Holder from '@components/holder/holder';

const About = (props) => {

  return (
    <div 
      className='about overflow-hidden'
    >
      <Container>
        <Row>
          <Col 
            lg="7" 
            className="col--left"
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <h4>{props.Title}</h4>
            <p>
              <ReactMarkdown source={props.Content} />
            </p>
            <Holder maxWidth="280px" mobile={false}>
              <Link to={`/${props.CTA_URL}`} className="btn btn-outline-primary">
                {props.CTA_Label}
              </Link>
            </Holder>
          </Col>
          <Col 
            lg={{span: 4, offset: 1}} 
            className="col--right"
            data-sal="slide-left"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <h5>How can we help you?</h5>
            <ListImageBullets list={props.Services} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default About;
