import React from "react";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown"
import CalendlyCTAOne from '@components/calendly/global-cta-one';
import CalendlyCTATwo from '@components/calendly/global-cta-two';

/**
 * Assets
 */
import './assets/styles/_index.scss';

const EntryHeading = (props) => {
    
    if(props.Content_Alignment == "Center") {
        var css_class = "entry-heading align-content-all-center"
    }
    else
    {
        var css_class = "entry-heading"
    }

  return (
    <div className={css_class}>
        {props.Sub_Title != null &&
        <h6>{props.Sub_Title}</h6>
        }
        <h3>{props.Title}</h3>
        {props.Content != null &&
        <p>
            <ReactMarkdown source={props.Content} />
        </p>
        }
        <div className="entry-heading__buttons">
            {props.CTA_1_URL != null && props.CTA_1_URL != "" && props.CTA_1_URL != "#calendly" &&
                <Link to={`/${props.CTA_1_URL}`} className="btn btn-primary">{props.CTA_1_Label}</Link>
            }
            {props.CTA_1_URL != null && props.CTA_1_URL != "" && props.CTA_1_URL == "#calendly" &&
                <CalendlyCTAOne url={props.Calendly}/>
            }

            {props.CTA_2_URL != null && props.CTA_2_URL != "" && props.CTA_2_URL != "#calendly" &&
                <Link to={`/${props.CTA_2_URL}`} className="btn btn-outline-primary">{props.CTA_2_Label}</Link>
            }
            {props.CTA_2_URL != null && props.CTA_2_URL != "" && props.CTA_2_URL == "#calendly" &&
                <CalendlyCTATwo url={props.Calendly}/>
            }
        </div>
    </div>
  );
}

export default EntryHeading;