import React, { useState, Component } from 'react';

import { withPrefix } from "gatsby"
import Helmet from "react-helmet"



class ReapitWidget extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://digital-staging.reapit.net/hlr/RDAPlugin?ApiKey=fdc632a9de16d397eec34fe976f1d688&version=0.2";
    this.div.appendChild(script);
  }
  render() {
    return (
    <>
      <Helmet>
        <link type="text/css" rel="stylesheet" href="https://digital-staging.reapit.net/hlr/RDAPluginCSS?version=0.2" />
      </Helmet>
      <div className="App" ref={el => (this.div = el)}>
        <a class={this.props.class}>{this.props.label}</a>
      </div>
    </>
    );
  }
}



export default ReapitWidget;
