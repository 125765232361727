import React from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'gatsby'

const breadcrubs = (props,{className}) => {
  return (
    <div className={classNames('beadcrumbs', className)}>
      <Container>
        <Link to="/">Home</Link>
        {props.Parent_URL != null &&
        <>
        <span>&gt;</span>
        <Link to={`/${props.Parent_URL}`}>{props.Parent_Label}</Link> 
        </>       
        }
        {props.Parent_1_URL != null &&
        <>
        <span>&gt;</span>
        <Link to={`/${props.Parent_URL}/${props.Parent_1_URL}`}>{props.Parent_1_Label}</Link> 
        </>       
        }
        <span>&gt;</span>
        {props.Page_1}
      </Container>
    </div>
  )
}

export default breadcrubs
