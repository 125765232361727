import React, { useState, Fragment } from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import $ from 'jquery'

/**
 * Context API
 */
import { useGlobalState } from '@store/hooks';

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Assest
 */
import '../navigation/assets/styles/_index.scss';
import CloseIcon from '@static/svg/close_icon.svg';

const Search = (props,{active, navigationToggle}) => {
  const [expanded, setExpanded] = useState(false);
  const { services: modalServices } = useGlobalState('modals');
  const handleClick = () => {
    navigationToggle();
    setExpanded(false);
  };

  const handleExpand = (event) => setExpanded(!event.target.parentNode.classList.contains('show'));
  const handleClose = () => expanded ? setExpanded(false) : navigationToggle();
  const modalOpen = (event) => {
    event.preventDefault();
    $("html").addClass("bg-scroll-disable");
    modalServices.modalToggle({name: 'property_search', active: true});
  }

  return (
  <>
    <Nav.Link href="/" onClick={modalOpen} className={props.class}>{props.label}</Nav.Link>
  </>
  )
}

export default Search;
